
import mediaHelper from "@libraryHelpers/media.js";

export default {
	name: "CoreBlockRows",
	props: {
		index: {
			type: Number,
			default: 0,
		},
		nestedLevel: {
			type: Number,
			default: 0,
		},
		settings: {
			type: Object,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			croppedImage: null,
		};
	},
	computed: {
		blockChildren() {
			return this.settings?.blocks || [];
		},
		blockChildrenCount() {
			return this.blockChildren.length;
		},
		blockId() {
			return this.settings.id;
		},
		blockVariants() {
			return Object.entries(this.settings?.variants || {}).map(
				([key, value]) => {
					return {
						key,
						value,
					};
				}
			);
		},
		blockVariantLayout() {
			return this.settings?.variants?.layout || "rows";
		},
		hasChildren() {
			return this.settings?.blocks?.length;
		},
		nestedLevelNext() {
			return this.nestedLevel + 1;
		},
		bodyClasses() {
			const classList = [
				"block__body",
				...this.blockVariants.map((variant) => {
					return `${variant.key}-${variant.value}`;
				}),
			];
			if (!this.hasChildren) {
				classList.push("block__body--empty");
			}
			return classList;
		},
		bodyStyles() {
			if (this.mediaIsImg) {
				return {
					"background-image": `url('${this.croppedSrc}')`,
					"background-position": this.mediaBackgroundPosition,
					"background-repeat": "no-repeat",
					"background-size": "cover",
					width: "100%",
					height: this.settings?.rowHeight || "500px",
				};
			}
			return {
				height: this.settings?.rowHeight || "auto",
			};
		},
		mediaIsImg() {
			return !!this.mediaSrc && mediaHelper.isImage(this.mediaSrc);
		},
		mediaSrc() {
			return this.settings?.backgroundMediaSrc;
		},
		croppedSrc() {
			return this.mediaMetadata?.image
				? this.croppedImage
				: mediaHelper.getEncodedURI(this.mediaSrc);
		},
		mediaBackgroundPosition() {
			return this.mediaMetadata?.focal?.bgPosition || "center";
		},
		mediaMetadata() {
			return this.settings?.mediaMetadata?.backgroundMediaSrc;
		},
		attr() {
			return {
				...(this?.settings?.alt && { "aria-label": this.settings.alt }),
			};
		},
		/*
			todo the childBlockComponent property is only useful for the builder
			so it should be separated from this block's logic
		*/
		childBlockComponent() {
			if (this.isEditable) {
				return "ForgeControlBlock";
			}
			return "CoreBlock";
		},
	},
};
