import { render, staticRenderFns } from "./CoreBlockRows.vue?vue&type=template&id=69212a5f&"
import script from "./CoreBlockRows.vue?vue&type=script&lang=js&"
export * from "./CoreBlockRows.vue?vue&type=script&lang=js&"
import style0 from "./CoreBlockRows.vue?vue&type=style&index=0&id=69212a5f&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../Core.Generator/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreBlockImageCropped: require('/usr/src/app/Core.Library/src/components/block/CoreBlockImageCropped.vue').default})
