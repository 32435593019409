
export default {
	name: "CoreBlockImageCropped",
	props: {
		imageSrc: {
			type: String,
			default: "",
		},
		mediaMetadata: {
			type: Object,
			default: () => {},
		},
	},
	watch: {
		mediaMetadata() {
			this.updateCanvas();
		},
	},
	mounted() {
		this.updateCanvas();
	},
	methods: {
		getMimeTypeByFilename(filename) {
			try {
				const extension = filename.split(".").splice(-1).join();

				const mimeTypeMap = {
					// Add more extensions and MIME types as needed
					jpg: "image/jpeg",
					jpeg: "image/jpeg",
					png: "image/png",
				};
				const lowercaseExtension = extension.toLowerCase();
				return mimeTypeMap[lowercaseExtension] || "image/png";
			} catch {
				return "image/png";
			}
		},
		updateCanvas() {
			if (!this.mediaMetadata?.image) {
				return this.$emit("loaded", "");
			} else {
				const imageToCrop = new Image();
				imageToCrop.crossOrigin = "anonymous";
				imageToCrop.onload = () => {
					const transformedImage = this.transformCanvas(imageToCrop);
					const croppedImage = this.cropCanvas(transformedImage);
					const type = this.getMimeTypeByFilename(this.imageSrc);
					const dataUrl = croppedImage?.toDataURL(type, 0.8);
					this.$emit("loaded", dataUrl);
				};

				imageToCrop.src = this.imageSrc;
			}
		},
		transformCanvas(source) {
			try {
				const { image } = this.mediaMetadata;
				const sourceImage = source;
				const canvas = document.createElement("canvas");

				if (canvas && image && sourceImage) {
					let postTranslateX, postTranslateY;
					const flipVertical = image.transforms.flip.vertical
						? -1
						: 1;
					const flipHorizontal = image.transforms.flip.horizontal
						? -1
						: 1;
					const convertedDegrees = Math.abs(
						image.transforms.rotate % 360
					);

					//Invert axis when canvas is rotated
					if ([90, 270].includes(convertedDegrees)) {
						canvas.height = sourceImage.width;
						canvas.width = sourceImage.height;
						postTranslateX = canvas.height / 2;
						postTranslateY = canvas.width / 2;
					} else {
						canvas.height = sourceImage.height;
						canvas.width = sourceImage.width;
						postTranslateX = canvas.width / 2;
						postTranslateY = canvas.height / 2;
					}

					const ctx = canvas.getContext("2d");
					ctx.translate(canvas.width / 2, canvas.height / 2);
					ctx.rotate((convertedDegrees * Math.PI) / 180);
					ctx.scale(flipHorizontal, flipVertical);
					ctx.translate(-postTranslateX, -postTranslateY);
					ctx.drawImage(sourceImage, 0, 0);
					return canvas;
				}
			} catch (e) {
				this.$emit("loaded", "");
			}
		},
		cropCanvas(sourceCanvas) {
			try {
				const { coordinates } = this.mediaMetadata;
				const sourceImage = sourceCanvas;
				const canvas = document.createElement("canvas");

				if (canvas && coordinates && sourceImage) {
					canvas.height = coordinates.height;
					canvas.width = coordinates.width;
					const ctx = canvas.getContext("2d");
					ctx.drawImage(
						sourceImage,
						coordinates.left,
						coordinates.top,
						coordinates.width,
						coordinates.height,
						0,
						0,
						coordinates.width,
						coordinates.height
					);
					return canvas;
				}
			} catch (e) {
				this.$emit("loaded", "");
			}
		},
	},
};
